<template>
  <fragment>
    <div :class="['sidebar_page', { 'left_side_closed': !showSidebar }]">
      <app-navbar></app-navbar>

      <div class="container-fluid">
        <div class="row flex-xl-nowrap">
          <loading :active.sync="isPageLoading" is-full-page></loading>

          <company-advanced-search :showSidebar="showSidebar" @perform-search="performSearch"></company-advanced-search>

          <div class="col-xl px-xl-4 py-4 right_side">
            <div class="page-title d-flex align-items-center">
              <button type="button" class="mr-1 bg-transparent border-0 d-print-none" @click="toggleSidebar()" aria-label="Toggle search">
                <i class="fas fa-lg" v-bind:class="[showSidebar ? 'fa-chevron-left': 'fa-chevron-right' ]"></i>
              </button>
              <h1>Find A Company</h1>
            </div>

            <div class="page-content">
              <data-table
                :data="searchResults.results"
                :columns="columns"
                :total="searchResults.total"
                :query="searchCriteria"
                :selectedRows="selectedRecords"
                :selectable="true"
                :isLoading="isDataLoading"
                :column-filter="$permissions().hasCompanySearchColumns"
                @selected-columns-changed="onSelectedColumnsChanged"
                @column-sort-changed="onColumnSortChanged"
                @page-size-changed="onPageSizeChanged"
              >
                <template v-slot:header-custom-btns>
                  <button type="button" class="btn btn-outline-dark" @click="sendEmail" :disabled="selectedRecords.length === 0" v-if="$permissions().hasCompanySendEmail">
                    Send Email
                  </button>
                  <button type="button" class="btn btn-outline-dark ld-ext-right" :class="{ 'running' : isExporting }"
                      @click="exportDataset" :disabled="isExporting || searchResults.results.length === 0" v-if="$permissions().hasCompanyExcelExport">
                    Export to Excel
                    <div class="ld ld-ring ld-spin"></div>
                  </button>
                  <button class="btn btn-outline-dark" type="button" onclick="window.print();">
                    Print
                  </button>
                </template>
                <template v-slot:name="{ row }">{{ row.name }}</template>
                <template v-slot:lastLoggedIn="{ row }">
                  {{ row.lastLoggedIn || '---' | datetime('MMM dd yyyy') }}
                </template>
                <template v-slot:address.city="{ row }">{{ row.city }}</template>
                <template v-slot:memberships.zone="{ row }">
                  <span v-for="(membership, index) in row.memberships" :key="'company-search-membership-zone-' + index">
                    {{ membership.zone }} <br />
                  </span>
                  <span v-if="row.memberships.length === 0">---</span>
                </template>
                <template v-slot:memberships.role="{ row }">
                  <span v-for="(membership, index) in row.memberships" :key="'company-search-membership-role-' + index">
                    {{ membership.role }} <br />
                  </span>
                  <span v-if="row.memberships.length === 0">---</span>
                </template>
                <template v-slot:memberships.expiry="{ row }">
                  <span v-for="(membership, index) in row.memberships" :key="'company-search-membership-expiry-' + index">
                    {{ membership.expiryDate | datetime('MMM dd yyyy') }} <br />
                  </span>
                  <span v-if="row.memberships.length === 0">---</span>
                </template>
                <template v-slot:createdOn="{ row }">
                  {{ row.createdDate || '---' | datetime('MMM dd yyyy') }}
                </template>
                <template v-slot:users.length="{ row }">
                  {{ row.userCount }}
                </template>
                <template v-slot="{ row }">
                  <td>
                    <router-link v-if="row.allowEdit" :to="'/company/edit/' + row.id" class="btn btn-link btn-sm" role="button" :aria-label="'Edit ' + row.name">
                      <i class="far fa-edit"></i>
                    </router-link>
                  </td>
                </template>
                <template v-slot:loading>
                  <loading :active.sync="isDataLoading" :is-full-page="false"></loading>
                </template>
                <template v-slot:no-records>
                  <div class="alert alert-warning" role="alert">No records found.</div>
                </template>
              </data-table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal id="bv-modal-company-saved-search" hide-header hide-footer :no-close-on-backdrop="true">
      <company-saved-search :searchCriteria="searchCriteria"></company-saved-search>
    </b-modal>

    <b-modal id="bv-modal-company-send-email" size="lg" hide-header hide-footer :no-close-on-backdrop="true">
      <company-send-email></company-send-email>
    </b-modal>
  </fragment>
</template>

<style scoped>
.invis-button {
  border: 0;
  background-color: transparent;
}
.invis-button:focus {
  outline: none;
}

.fa {
  color: #017991;
}
.dropdown-toggle::after {
  color: #017991;
}
</style>

<script>
import AppNavBar from "@/components/app-navbar.vue";
import CompanyAdvancedSearch from "@/views/company/components/company-advanced-search.vue";
import CompanySavedSearch from "@/views/company/components/company-saved-search.vue";
import CompanySendEmail from "@/views/company/components/company-send-email.vue";
import { DataTable } from "@/components/datatable/";

import { CompanyService } from "@/services/";
import FileDownload from "@/utils/fileDownload";
import { mapGetters, mapActions } from "vuex";
import Toast from "@/utils/toast";

const STORAGE_KEY_COMPANY_TABLE_SELECTED_COLUMNS = 'company-table-selected-columns';
const STORAGE_KEY_COMPANY_TABLE_COLUMN_SORTING = 'company-table-column-sorting';
const STORAGE_KEY_COMPANY_TABLE_PAGE_SIZE = 'company-table-page-size';

const defaultSearchCriteria = {
  page: 1,
  pageSize: 10,
  sortOrder: null,
  sortDirection: null,
};

export default {
  name: "company-search",
  components: {
    'app-navbar': AppNavBar,
    CompanySavedSearch,
    CompanySendEmail,
    CompanyAdvancedSearch,
    DataTable
  },
  data: function() {
    return {
      isPageLoading: true,
      isDataLoading: false,
      isExporting: false,
      showSidebar: true,
      searchCriteria: {},
      selectedRecords: [],
      searchResults: { total: 0, results: [] },
      columns: [
        {
          display: "Company Name",
          field: "name",
          exportFieldName: "name",
          sortable: true
        },
        {
          display: "Last Login Date",
          field: "lastLoggedIn",
          exportFieldName: "lastLoggedIn"
        },
        {
          display: "City",
          field: "address.city",
          exportFieldName: "city",
          sortable: true
        },
        {
          display: "Zones",
          field: "memberships.zone",
          exportFieldName: "zone"
        },
        {
          display: "Membership Types",
          field: "memberships.role",
          exportFieldName: "role"
        },
        {
          display: "Expiry Date",
          field: "memberships.expiry",
          exportFieldName: "expiryDate"
        },
        { display: "Created Date", field: "createdOn", exportFieldName: "createdOn", sortable: true },
        { display: "User Count", field: "users.length", exportFieldName: "users.length" },
        { ignore: true }
      ]
    };
  },
  watch: {
    "searchCriteria.page": function (newValue, oldValue) {
      if (typeof oldValue !== 'undefined' && newValue !== oldValue) {
        this.getSearchResults();
      }
    },
    "searchCriteria.pageSize": function (newValue, oldValue) {
      if (typeof oldValue !== 'undefined' && newValue !== oldValue) {
        this.searchCriteria.page = 1;
        this.getSearchResults();
      }
    },
    "searchCriteria.sortDirection": function (newValue, oldValue) {
      if (typeof oldValue !== 'undefined' && newValue !== oldValue) {
        this.getSearchResults();
      }
    },
    "searchCriteria.sortOrder": function (newValue, oldValue) {
      if (typeof oldValue !== 'undefined' && newValue !== oldValue) {
        this.getSearchResults();
      }
    },
  },
  computed: {
    ...mapGetters(["getZones", "getRoles"]),
    visibleColumnNames() {
      return this.columns
        .filter(column => !column.ignore && column.isVisible)
        .map(column => column.exportFieldName);
    }
  },
  methods: {
    exportDataset() {
      let exportParams = {
        search: { ...this.searchCriteria },
        columns: []
      };

      this.isExporting = true;

      return CompanyService.excel(exportParams)
        .then(response => {
          FileDownload("CompanyExport.xlsx", response.data);
          Toast.success(this, "Successfully exported companies!");
        })
        .catch(() => Toast.danger(this, "Oops! An error has occured."))
        .finally(() => this.isExporting = false);
    },
    performSearch(advancedSearch) {
      this.searchCriteria = { ...this.searchCriteria, ...advancedSearch };
      this.getSearchResults();
    },
    toggleSidebar() {
      this.showSidebar = !this.showSidebar;
    },
    getSearchResults() {
      if (!this.isPageLoading) {
        this.isDataLoading = true;
      }

      return CompanyService.search(this.searchCriteria).then(response => {
        this.searchResults = response.data;
        this.isDataLoading = false;
      });
    },
    sendEmail() {
      this.fetchCompanyEmailAddresses(this.selectedRecords).then(response => {
        let checkHasAvailableEmailAddress = (item) => item.email || item.users.length;
        if (!response.some(checkHasAvailableEmailAddress)) {
         Toast.danger(this, "Oops! There are no email addresses available");
        } else {
          this.$bvModal.show("bv-modal-company-send-email");
        }
      });
    },
    onPageSizeChanged (pageSize) {
      window.localStorage.setItem(STORAGE_KEY_COMPANY_TABLE_PAGE_SIZE, JSON.stringify(pageSize));
    },
    onSelectedColumnsChanged(columns) {
      let activeColumns = columns.map(column => ({ field: column.field, isVisible: column.isVisible }));
      window.localStorage.setItem(STORAGE_KEY_COMPANY_TABLE_SELECTED_COLUMNS, JSON.stringify(activeColumns));
    },
    onColumnSortChanged(sort) {
      window.localStorage.setItem(STORAGE_KEY_COMPANY_TABLE_COLUMN_SORTING, JSON.stringify(sort));
    },
    onSubmit() {
      this.getSearchResults();
    },
    ...mapActions([
      "fetchRoles",
      "fetchBusinessCategories",
      "fetchLabourTypes",
      "fetchLabourOfWorks",
      "fetchClassificationOfWork",
      "fetchCategories",
      "fetchGroupedCategories",
      "fetchCompanies",
      "fetchCompanySearches",
      "fetchCompanyEmailAddresses",
      "fetchZones"
    ])
  },
  beforeMount() {
    let savedColumnSort = JSON.parse(window.localStorage.getItem(STORAGE_KEY_COMPANY_TABLE_COLUMN_SORTING)) || {};
    let savedPageSort = JSON.parse(window.localStorage.getItem(STORAGE_KEY_COMPANY_TABLE_PAGE_SIZE)) || {};
    this.searchCriteria = { ...defaultSearchCriteria, ...savedColumnSort, ...savedPageSort, ...this.$route.query };
  },
  mounted: function() {
    let savedColumns = JSON.parse(window.localStorage.getItem(STORAGE_KEY_COMPANY_TABLE_SELECTED_COLUMNS)) || [];
    this.columns.forEach(column => Object.assign(column, savedColumns.find(i => i.field === column.field)));

    Promise.all([
      this.fetchCompanySearches(),
      this.fetchRoles(),
      this.fetchBusinessCategories(),
      this.fetchLabourTypes(),
      this.fetchLabourOfWorks(),
      this.fetchClassificationOfWork(),
      this.fetchGroupedCategories(),
      this.fetchZones(),
      this.getSearchResults()
    ])
    .then(() => {
      this.isPageLoading = false;
    })
    .catch(() => {
      this.isPageLoading = false;
      let message = this.$createElement('span', {}, ['Oops! An error has occured and some data could not be loaded', this.$createElement('br'), 'Please contact an administrator']);
      Toast.danger(this, [message], { autoHideDelay: 7500 });
    });
  }
};
</script>
