<template>
  <fragment>
    <form v-on:submit.prevent="onSubmit">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Save Company Search</h5>
      </div>

      <div class="modal-body">
        <div class="pr-2 flex-fill">
          <label class="d-flex align-items-center font-weight-bold" for="name">
            Name
            <span class="red-dot ml-3"></span>
          </label>
          <input
            class="w-100 rounded p-1 px-2"
            :class="{ 'input-validation-error': errors.has('name') }"
            id="name"
            name="name"
            placeholder="Enter search name"
            autocomplete="off"
            type="text"
            v-validate="'required'"
            v-model="name"
            data-vv-as="Name"
          />
          <span class="field-validation-error" v-if="errors.has('name')">The Name field is required.</span>
        </div>
      </div>

      <div class="modal-footer">
        <button type="submit" class="btn btn-primary">Save</button>
        <button type="button" class="btn btn-secondary" @click="closeModal">Close</button>
      </div>
    </form>
  </fragment>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Toast from "@/utils/toast";

export default {
  name: "company-saved-search",
  props: ["searchCriteria"],
  data: function() {
    return {
      name: null
    };
  },
  computed: {
    ...mapGetters(["getCurrentUser"])
  },
  methods: {
    async onSubmit() {
      var isFormValid = await this.$validator.validate();

      if (!isFormValid) {
        return;
      }

      let model = {
        name: this.name,
        userId: this.getCurrentUser.id,
        ...this.searchCriteria
      };

      this.saveCompanySearch(model).then(() => {
        Toast.success(this, "Successfully saved!");
        this.$bvModal.hide("bv-modal-company-saved-search");
      });
    },
    closeModal() {
      this.$bvModal.hide("bv-modal-company-saved-search");
    },
    ...mapActions(["saveCompanySearch"])
  }
};
</script>
