<template>
  <div id="sidebar" class="col col-xl-3 px-xl-4 py-4 border-right bg-light left_side d-print-none" :hidden="!showSidebar">
    <form v-on:submit.prevent="onSubmit">
      <fieldset>
        <legend>Narrow Your Results</legend>

        <div class="form-group">
          <label class="sr-only" for="mySearchesDropdown">Saved searches</label>
          <div class="input-group">
            <select id="mySearchesDropdown" name="mySearchesDropdown" class="custom-select" ref="savedSearchDropdown" @change="selectSavedSearch">
              <option value>My Searches</option>
              <option v-for="search in getCompanySavedSearches" :key="search.id" :value="search.id">{{ search.name }}</option>
            </select>
            <div class="input-group-append">
              <button class="btn btn-sm btn-outline-danger" type="button" aria-label="Delete saved search" @click="deleteSavedSearch">
                <i class="fas fa-trash-alt"></i>
              </button>
            </div>
          </div>
        </div>

        <div class="form-group">
          <label for="Keywords">Keyword</label>
          <input class="form-control w-100 rounded p-1 px-2" id="Keywords" name="Keywords" placeholder="Search by Keyword" autocomplete="off" type="text" v-model="searchCriteria.keyword" />
        </div>
      </fieldset>

      <fieldset class="mt-2 advanced-search" v-if="$permissions().hasProjectAdvancedSearch">
        <legend>Advanced Search</legend>

        <div class="card" id="zone-level-card">
          <div class="card-header row align-items-center" data-toggle="collapse" data-target="#zone-level-collapse" aria-expanded="false">
            <div class="col card-text">
              <i class="fas fa-map fa-lg mr-2" aria-hidden="true"></i>
              <span>Zone</span>
            </div>
            <i class="fas mx-2 fa-chevron-down" aria-hidden="true"></i>
          </div>
          <div class="collapse" id="zone-level-collapse">
            <div class="card-body">
              <div class="form-group">
                <label class="sr-only" for="zone">Zone</label>
                <v-select id="zone" name="zone" class="v-select-multi" style="width: 100%" multiple label="name" :placeholder="'All'"
                  :reduce="zone => zone.id" :options="getZones" v-model="searchCriteria.zoneIds" :closeOnSelect="false">
                </v-select>
              </div>
            </div>
          </div>
        </div>

        <div class="card" id="membership-level-card">
          <div class="card-header row align-items-center" data-toggle="collapse" data-target="#membership-level-collapse" aria-expanded="false">
            <div class="col card-text">
              <i class="fas fa-align-left fa-lg mr-2" aria-hidden="true"></i>
              <span>Membership Level</span>
            </div>
            <i class="fas mx-2 fa-chevron-down" aria-hidden="true"></i>
          </div>

          <div class="collapse" id="membership-level-collapse">
            <div class="card-body">
              <div class="form-group">
                <label class="sr-only" for="membershipLevel">Membership Level</label>
                <v-select id="membershipLevel" name="membershipLevel" class="v-select-multi" style="width: 100%" multiple
                  label="name" :placeholder="'All'" :reduce="membership => membership.id" :options="getMembershipRoles"
                  v-model="searchCriteria.membershipIds" :closeOnSelect="false">
                </v-select>
              </div>
            </div>
          </div>
        </div>

        <div class="card" id="business-category-card">
          <div class="card-header row align-items-center" data-toggle="collapse" data-target="#business-category-collapse" aria-expanded="false">
            <div class="col card-text">
              <i class="fas fas fa-briefcase fa-lg mr-2" aria-hidden="true"></i>
              <span>Business Category</span>
            </div>
            <i class="fas mx-2 fa-chevron-down" aria-hidden="true"></i>
          </div>
          <div class="collapse" id="business-category-collapse">
            <div class="card-body">
              <div class="form-group">
                <label class="sr-only" for="businessCategories">Business Categories</label>
                <v-select id="businessCategories" name="businessCategories" class="v-select-multi" style="width: 100%" multiple
                  label="name" :placeholder="'All'" :reduce="category => category.id" :options="getBusinessCategories"
                  v-model="searchCriteria.businessCategoryIds" :closeOnSelect="false">
                </v-select>
              </div>
            </div>
          </div>
        </div>

        <div class="card" id="labour-type-card">
          <div class="card-header row align-items-center" data-toggle="collapse" data-target="#labour-type-collapse" aria-expanded="false">
            <div class="col card-text">
              <i class="fas fa-hard-hat fa-lg mr-2" aria-hidden="true"></i>
              <span>Labour Type</span>
            </div>
            <i class="fas mx-2 fa-chevron-down" aria-hidden="true"></i>
          </div>
          <div class="collapse" id="labour-type-collapse">
            <div class="card-body">
              <div class="form-group">
                <label class="control2 control-radio">
                  All
                  <input id="labourTypeId" name="labourTypeId" type="radio" :value="null" v-model="searchCriteria.labourTypeId" checked="checked" />
                  <span class="control_indicator2"></span>
                </label>

                <div v-for="labourType in getLabourTypes" :key="labourType.id">
                  <label class="control2 control-radio">
                    {{ labourType.name }}
                    <input type="radio" name="labourTypeId" :value="labourType.id" v-model="searchCriteria.labourTypeId" />
                    <div class="control_indicator2"></div>
                  </label>
                </div>
              </div>

              <div id="company-labour-of-works-container" v-if="searchCriteria.labourTypeId && getLabourOfWork.length > 0">
                <div class="form-group">
                  <div class="form-check">
                    <input id="ClassificationOfWorks" class="form-check-input" type="checkbox" name="ClassificationOfWorks" :value="work.id" v-model="searchCriteria.labourOfWorkIds" />
                    <label class="control control-checkbox" v-for="work in getLabourOfWork" :key="work.id" for="ClassificationOfWorks">{{ work.name }}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card" id="classes-card">
          <div class="card-header row align-items-center" data-toggle="collapse" data-target="#classes-collapse" aria-expanded="false">
            <div class="col card-text">
              <i class="fas fa-border-all fa-lg mr-2" aria-hidden="true"></i>
              <span>Classification of Work</span>
            </div>
            <i class="fas mx-2 fa-chevron-down" aria-hidden="true"></i>
          </div>
          <div class="collapse" id="classes-collapse">
            <div class="card-body">
              <div class="form-group">
                <label class="sr-only" for="classificationOfWork">Classification Of Work</label>
                <v-select id="classificationOfWork" name="classificationOfWork" class="v-select-multi" style="width: 100%" multiple
                  label="name" :placeholder="'All'" :reduce="cow => cow.id" :options="getClassificationOfWorks"
                  v-model="searchCriteria.classificationOfWorkIds" :closeOnSelect="false">
                </v-select>
              </div>
            </div>
          </div>
        </div>
      </fieldset>

      <button type="submit" class="btn-primary btn btn-block">Search</button>

      <div class="col mt-2">
        <div class="row">
          <button type="button" class="col btn-secondary btn" title="Save Search" @click="saveSearch"><i class="far fa-save"></i> Save Search</button>
          <button type="button" class="col btn-secondary btn" title="Clear All" @click="clearSearch">Clear All</button>
        </div>
      </div>
    </form>
  </div>
</template>

<style scoped>
.card {
  margin-bottom: 8px;
  cursor: pointer;
}
</style>

<script>
import { mapActions, mapGetters } from "vuex";
import cloneDeep from "lodash/cloneDeep";
import Toast from "@/utils/toast";
import MessageBox from "@/utils/messagebox";

import "@/styles/search-sidebar.css";

const defaultSearchCriteria = {
  keyword: null,
  zoneIds: [],
  membershipIds: [],
  businessCategoryIds: [],
  labourTypeId: null,
  labourOfWorkIds: [],
  classificationOfWorkIds: [],
  categoryIds: []
};

export default {
  name: "company-advanced-search",
  props: {
    showSidebar: { type: Boolean, default: () => true }
  },
  data: function() {
    return {
      searchCriteria: null,
      datepickerOptions: {
        useCurrent: false,
        format: "MM/DD/YYYY"
      }
    };
  },
  computed: {
    getLabourOfWork: function() {
      if (this.searchCriteria.labourTypeId) {
        let selectedLabourType = this.getLabourTypes.find(type => type.id === this.searchCriteria.labourTypeId);
        if (selectedLabourType) {
          return selectedLabourType.labourOfWorks;
        }
      }
      return [];
    },
    getClassificationOfWorks(){
      let classificationOfWorks = this.getClassificationOfWork
      return classificationOfWorks.sort((a,b) => a.name.localeCompare(b.name))
    },
    ...mapGetters([
      "getBusinessCategories",
      "getCompanySavedSearches",
      "getCompanies",
      "getLabourTypes",
      "getGroupedCategories",
      "getClassificationOfWork",
      "getMembershipRoles",
      "getZones"
    ]),
  },
  methods: {
    clearSearch() {
      this.$nextTick(() => {
        // ideally this all gets moved into vuex to handle better across components
        this.searchCriteria.keyword = null;
        this.searchCriteria.zoneIds = [];
        this.searchCriteria.membershipIds = [];
        this.searchCriteria.businessCategoryIds = [];
        this.searchCriteria.labourTypeId = null;
        this.searchCriteria.labourOfWorkIds = [];
        this.searchCriteria.classificationOfWorkIds = [];
        this.searchCriteria.categoryIds = [];
      });
    },
    async deleteSavedSearch () {
      let searchId = this.$refs.savedSearchDropdown.value;

      if (searchId) {
        let confirm = await MessageBox.confirm(this, "Remove Saved Search", "Are you sure you want to remove the selected saved search?");

        if (confirm) {
          this.removeCompanySearch(searchId)
            .then(() => Toast.success(this, "Successfully removed saved search!"))
            .catch(() => Toast.danger(this, "Oops! An error has occured"));
        }
      }
    },
    onSubmit() {
      this.searchCriteria.page = 1;
      let params = cloneDeep(this.searchCriteria);
      this.$emit('perform-search', params);
    },
    saveSearch() {
      this.$bvModal.show("bv-modal-company-saved-search");
    },
    selectSavedSearch() {
      let searchId = this.$refs.savedSearchDropdown.value;
      let search = this.getCompanySavedSearches.find(i => i.id === searchId);

      if (search) {
        let parsed = JSON.parse(JSON.stringify(search));

        // remove unnecessary items
        delete parsed['id'];
        delete parsed['name'];
        delete parsed['userId'];

        this.searchCriteria = parsed;
        this.onSubmit();
      }
    },
    ...mapActions([
      "removeCompanySearch"
    ])
  },
  beforeMount() {
    this.searchCriteria = { ...defaultSearchCriteria };
  },
};
</script>
