<template>
  <fragment>
    <form v-on:submit.prevent="onSubmit">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">Send Email</h5>
      </div>

      <div class="modal-body">
        <div class="form-group">
          <label for="cc">
            To
            <span class="red-dot ml-3"></span>
          </label>
          <multiselect
            name="to"
            v-model="email.to"
            :options="records"
            :multiple="true"
            group-values="users"
            group-label="name"
            :group-select="true"
            track-by="email"
            label="email"
            v-validate="'required'"
            :class="{ 'is-invalid': errors.has('to') }"
            data-vv-as="To"
          ></multiselect>
          <span class="invalid-feedback" v-if="errors.has('to')">The To field is required</span>
        </div>

        <div class="form-group">
          <label for="cc">CC</label>
          <multiselect
            name="cc"
            v-model="email.cc"
            :options="records"
            :multiple="true"
            group-values="users"
            group-label="name"
            :group-select="true"
            track-by="email"
            label="email"
          ></multiselect>
        </div>

        <div class="form-group">
          <label for="bcc">BCC</label>
          <multiselect
            name="bcc"
            v-model="email.bcc"
            :options="records"
            :multiple="true"
            group-values="users"
            group-label="name"
            :group-select="true"
            track-by="email"
            label="email"
          ></multiselect>
        </div>

        <div class="form-group">
          <label for="subject">
            Subject
            <span class="red-dot ml-3"></span>
          </label>
          <input
            type="text"
            class="form-control"
            :class="{ 'is-invalid': errors.has('subject') }"
            id="subject"
            name="subject"
            v-validate="'required'"
            v-model="email.subject"
            data-vv-as="Subject"
          />
          <span class="invalid-feedback" v-if="errors.has('subject')">
            The Subject field is required
          </span>
        </div>

        <div class="form-group">
          <label for="body">
            Body
            <span class="red-dot ml-3"></span>
          </label>
          <app-editor
            id="body"
            name="body"
            class="w-100"
            :class="{ 'is-invalid': errors.has('body') }"
            v-model="email.body"
            v-validate="'required'"
            data-vv-as="Body"
          ></app-editor>
          <span class="invalid-feedback" v-if="errors.has('body')">
            The Body field is required
          </span>
        </div>
      </div>

      <div class="modal-footer">
        <button type="submit" class="btn btn-primary">Send Email</button>
        <button type="button" class="btn btn-secondary" @click="closeModal">Close</button>
      </div>
    </form>
  </fragment>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Toast from "@/utils/toast";
import AppEditor from "@/components/app-editor";

export default {
  name: "company-send-email",
  components: { AppEditor },
  data: function() {
    return {
      records: [],
      email: {
        to: [],
        cc: [],
        bcc: [],
        subject: null,
        body: null,
        from: 'theteam@bidcentral.ca'
      }
    };
  },
  computed: {
    ...mapGetters(["getCompanySendEmailAddresses"])
  },
  methods: {
    closeModal() {
      this.$bvModal.hide("bv-modal-company-send-email");
    },
    generateCompanyEmailList() {
      return this.getCompanySendEmailAddresses
        .filter(company => company.email || company.users.length)
        .map(company => {
          let mapUser = (user) => { return { name: `${user.firstName} ${user.lastName}`, email: user.email } };
          let mapCompany = (company) => { return { name: company.name, email: company.email } }

          let users = [];
          if (company.email) users.push({ ...mapCompany(company) })
          if (company.users.length) users.push(...company.users.map(mapUser))

          return { ...mapCompany(company), users: users };
        });
    },
    async onSubmit() {
      var isFormValid = await this.$validator.validate();

      if (!isFormValid) {
        return;
      }

      let model = {
        to: this.email.to.map(p => p.email),
        cc: this.email.cc.map(p => p.email),
        bcc: this.email.bcc.map(p => p.email),
        subject: this.email.subject,
        body: this.email.body
      };

      this.sendCompanyEmail(model).then(() => {
        Toast.success(this, "Successfully sent!");
        this.closeModal();
      });
    },
    ...mapActions(["sendCompanyEmail"])
  },
  mounted() {
    this.records = this.generateCompanyEmailList();
  },
};
</script>
